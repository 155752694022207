var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as amplitude from '@amplitude/analytics-browser';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../services/mobile-bridge/mobile-bridge';
import ServiceHandler from '../services/service-handler';
export var useAmplitude = function () {
    var location = useLocation();
    var getAmplitudeScreenTitle = function () {
        return (location === null || location === void 0 ? void 0 : location.pathname.replace(/(claim-points)\/.+/, '$1').replace(/^rewards-/, '').replace(/\W+/g, ' ').replace(/(lar|fpr) sd campaigns /, 'campaign ').trim().toLowerCase());
    };
    var logAmplitudeEvent = function (name, params) {
        var _a, _b, _c, _d;
        if (name === void 0) { name = 'unknown'; }
        if (params === void 0) { params = {}; }
        amplitude.track("rewards ".concat(name).toLowerCase().replace(/\W+/g, ' '), __assign(__assign({}, params), { screenName: getAmplitudeScreenTitle(), loyaltyExperience: "".concat(ServiceHandler.ConfigService.BRAND_ID, "_").concat(ServiceHandler.ConfigService.PROGRAM_COUNTRY), loyaltyMobile: isMobile() }));
        // Adobe Analytics
        if ((_a = window._satellite) === null || _a === void 0 ? void 0 : _a.track) {
            if (name.endsWith(' page viewed')) {
                var radUiType = void 0;
                if (window.innerWidth < 768) {
                    radUiType = 'mobile';
                }
                else if (window.innerWidth < 992) {
                    radUiType = 'tablet';
                }
                else {
                    radUiType = 'pc';
                }
                window['radUIVersion'] = 'ui:rad:' + radUiType;
                window['pageName'] = name.replace(' page viewed', '');
                window['digitaldata'] = {
                    page: {
                        pageName: "flmo:owner:".concat(ServiceHandler.ConfigService.BRAND_ID, ":").concat(window['pageName']).toLowerCase(),
                        siteSection: 'owner ford pass rewards',
                        hierarchy: 'owners:flmo:fpr:lp',
                        userLanguage: 'eng',
                        pageNameNoVehicle: "flmo:owner:".concat(ServiceHandler.ConfigService.BRAND_ID, ":").concat(window['pageName']).toLowerCase(),
                        client: 'ford',
                        site: 'ford.com/owners',
                        radUIVersion: window['radUIVersion'],
                        productCategory: 'seamless interface',
                        experienceType: radUiType,
                        pageType: 'owner:home page',
                        pageDomain: 'https://www.ford.com',
                    },
                    user: {
                        id: { guid: ((_c = (_b = window['fma']) === null || _b === void 0 ? void 0 : _b.CATBundle) === null || _c === void 0 ? void 0 : _c.userId.toLowerCase()) || undefined },
                        loginStatus: ServiceHandler.AuthenticationService.getIsAuthenticated() ? 'logged in' : 'logged out',
                    },
                };
                window._satellite.track('ownerFordPass');
            }
            if (name.endsWith(' tapped')) {
                window['digitaldata'] = {
                    onclick: {
                        onclickLinkName: "".concat(window['pageName'], " ").concat(params['labelText']).toLowerCase(),
                        onclick: (_d = params['ctaName']) === null || _d === void 0 ? void 0 : _d.toLowerCase(),
                    },
                };
                window._satellite.track('globalOnclick', window.digitaldata);
            }
        }
    };
    return { logAmplitudeEvent: logAmplitudeEvent, getAmplitudeScreenTitle: getAmplitudeScreenTitle };
};

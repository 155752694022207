import * as amplitude from '@amplitude/analytics-browser';
var AndroidBridge = /** @class */ (function () {
    function AndroidBridge() {
    }
    AndroidBridge.prototype.getCatBundle = function () {
        var _this = this;
        return new Promise(function (resolve) {
            resolve(_this.handleAndroidBridge());
        });
    };
    AndroidBridge.prototype.handleAndroidBridge = function () {
        var _a, _b;
        var tokenBundle = window['android'].getTokens();
        if ((_a = JSON.parse(window['android'].getTokens())) === null || _a === void 0 ? void 0 : _a.userId)
            amplitude.setUserId((_b = JSON.parse(window['android'].getTokens())) === null || _b === void 0 ? void 0 : _b.userId.toLowerCase());
        var CATBundleAndroid = JSON.parse(tokenBundle);
        var CATBundleExist = !!CATBundleAndroid && !!CATBundleAndroid.cat1_token;
        if (CATBundleExist) {
            return CATBundleAndroid;
        }
        else {
            // TODO(arkadiusz.lis@comarch.com): error handling
        }
    };
    return AndroidBridge;
}());
export { AndroidBridge };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LPS_API_PATH } from '../../constants';
import Axios from 'axios';
import ServiceHandler from '../service-handler';
import { SERVICE_CODE } from '../../models/api-helper';
import { buildQueryParams } from '../../utils/url.utils';
import { verifyAuthToken } from '../verify-auth-token/verify-auth-token';
import { isMobile } from '../mobile-bridge/mobile-bridge';
import * as amplitude from '@amplitude/analytics-browser';
var axios = Axios.create();
export var NO_AUTH_TOKEN = 'NO_AUTH_TOKEN';
export var EXPIRED = 'EXPIRED';
export var CLAIMED = 'CLAIMED';
var PointsBalanceService = /** @class */ (function () {
    function PointsBalanceService() {
    }
    PointsBalanceService.prototype.setCountryCode = function (code) {
        this.countryCode = code;
    };
    PointsBalanceService.prototype.request = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var authService, cfg, queryParams, url;
            return __generator(this, function (_c) {
                authService = ServiceHandler.AuthenticationService;
                cfg = ServiceHandler.ConfigService;
                if (isMobile() && !((_a = authService === null || authService === void 0 ? void 0 : authService.getCatBundle()) === null || _a === void 0 ? void 0 : _a.access_token))
                    return [2 /*return*/, Promise.resolve({ code: NO_AUTH_TOKEN })];
                if (!verifyAuthToken() && isMobile())
                    return [2 /*return*/, Promise.resolve({ code: EXPIRED })];
                queryParams = buildQueryParams({
                    rewardProgram: cfg.REWARD_PROGRAM,
                    programCountry: cfg.PROGRAM_COUNTRY,
                });
                url = cfg.LPS_BASE_URL + LPS_API_PATH.POINTS_BALANCES + queryParams;
                return [2 /*return*/, axios.get(url, {
                        headers: {
                            'Auth-Token': (_b = authService.getCatBundle()) === null || _b === void 0 ? void 0 : _b.access_token,
                            'Application-Id': cfg.OWNER_APP_ID,
                        },
                    })];
            });
        });
    };
    PointsBalanceService.prototype.requestElibibility = function (apiHelper) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.doRequest()
                        .then(function (response) {
                        var _a, _b, _c, _d;
                        if ([NO_AUTH_TOKEN, EXPIRED].some(function (s) { return s === (response === null || response === void 0 ? void 0 : response.code); })) {
                            apiHelper.handleFatalError(null, SERVICE_CODE.POINTS_ELIGIBLE_SERVICE, response.code, 'eligible');
                            return null;
                        }
                        return ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.ford) === null || _b === void 0 ? void 0 : _b.consentAccepted) || ((_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.lincoln) === null || _d === void 0 ? void 0 : _d.consentAccepted);
                    })
                        .catch(function (error) {
                        amplitude.track("rewards request elibibility error", {
                            error: error,
                        });
                        apiHelper.handleError(error, SERVICE_CODE.POINTS_ELIGIBLE_SERVICE, 'Eligibility Service');
                        return { error: error };
                    })];
            });
        });
    };
    PointsBalanceService.prototype.doRequest = function () {
        var _a, _b;
        var authService = ServiceHandler.AuthenticationService;
        var cfg = ServiceHandler.ConfigService;
        if (isMobile() && !((_a = authService === null || authService === void 0 ? void 0 : authService.getCatBundle()) === null || _a === void 0 ? void 0 : _a.access_token))
            return Promise.resolve({ code: NO_AUTH_TOKEN });
        if (!verifyAuthToken() && isMobile())
            return Promise.resolve({ code: EXPIRED });
        var queryParams = buildQueryParams({
            rewardProgram: cfg.REWARD_PROGRAM,
            programCountry: this.countryCode,
        });
        var url = cfg.LPS_BASE_URL + LPS_API_PATH.POINTS_BALANCES + queryParams;
        return axios.get(url, {
            headers: {
                'Auth-Token': (_b = authService.getCatBundle()) === null || _b === void 0 ? void 0 : _b.access_token,
                'Application-Id': cfg.OWNER_APP_ID,
            },
        });
    };
    return PointsBalanceService;
}());
export default PointsBalanceService;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fds-chevron-module__chevronWrap__ArweG {\n  position: relative;\n  padding: 0;\n  margin-right: 0;\n  display: inline-block;\n  padding-left: 8px;\n  transform-origin: 13px;\n  transition: all 0.5s ease !important;\n}\n\n.fds-chevron-module__up__k7Mam {\n  transform: rotate(270deg);\n  -webkit-transform: rotate(270deg);\n}\n\n.fds-chevron-module__down__SPo9h {\n  transform: rotate(90deg);\n  -webkit-transform: rotate(90deg);\n}\n\n.fds-chevron-module__left__O5lmY {\n  transform: rotate(180deg);\n  -webkit-transform: rotate(180deg);\n}", "",{"version":3,"sources":["webpack://./src/components/common/chevron/fds-chevron.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;EACA,sBAAA;EACA,oCAAA;AAAF;;AAGA;EACE,yBAAA;EACA,iCAAA;AAAF;;AAGA;EACE,wBAAA;EACA,gCAAA;AAAF;;AAGA;EACE,yBAAA;EACA,iCAAA;AAAF","sourcesContent":["@import \"main.scss\";\n.chevronWrap {\n  position: relative;\n  padding: 0;\n  margin-right: 0;\n  display: inline-block;\n  padding-left: 8px;\n  transform-origin: 13px;\n  transition: all 0.5s ease !important;\n}\n\n.up {\n  transform: rotate(270deg);\n  -webkit-transform: rotate(270deg);\n}\n\n.down {\n  transform: rotate(90deg);\n  -webkit-transform: rotate(90deg);\n}\n\n.left {\n  transform: rotate(180deg);\n  -webkit-transform: rotate(180deg);\n}\n\n.right svg {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chevronWrap": "fds-chevron-module__chevronWrap__ArweG",
	"up": "fds-chevron-module__up__k7Mam",
	"down": "fds-chevron-module__down__SPo9h",
	"left": "fds-chevron-module__left__O5lmY"
};
export default ___CSS_LOADER_EXPORT___;

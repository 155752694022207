import React from 'react';
import { Route, Routes } from 'react-router';
import { LogRoute } from './components/route/log-route';
import { NoMatchRoute } from './components/route/nomatch-route';
import { IndexRoute } from './components/route/index-route';
var OverviewView = React.lazy(function () { return import('./views-fpr/overview-view/overview-view'); });
export var BrandRoutes = function () {
    return (React.createElement(Routes, null,
        React.createElement(Route, { index: true, element: React.createElement(IndexRoute, { auth: '/dashboard', unauth: '/overview' }) }),
        React.createElement(Route, { element: React.createElement(LogRoute, null) },
            React.createElement(Route, { path: 'overview', element: React.createElement(OverviewView, null) })),
        React.createElement(Route, { path: '*', element: React.createElement(NoMatchRoute, { to: 'https://www.ford.com/404' }) })));
};

import * as amplitude from '@amplitude/analytics-browser';
var IOsBridge = /** @class */ (function () {
    function IOsBridge() {
    }
    IOsBridge.prototype.getCatBundle = function () {
        return this.handleIOSBridge();
    };
    IOsBridge.prototype.handleIOSBridge = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var iosHandler = function (e) {
                document.removeEventListener('PacificDidReceiveNativeCallback', iosHandler);
                var CATBundleIOS = e['detail']['parameters'];
                var CATBundleExist = !!CATBundleIOS && !!CATBundleIOS.cat1_token;
                if (CATBundleExist) {
                    resolve(CATBundleIOS);
                }
                else {
                    amplitude.track('rewards ios no bundle', { CATBundleIOS: CATBundleIOS || 'CATBundleIOS', e: e || 'e' });
                    _this.handleError('CATBundle does not exist');
                    reject('CATBundle does not exist');
                }
            };
            document.addEventListener('PacificDidReceiveNativeCallback', iosHandler);
            try {
                var tokenRequest = { action: 'getTokens', callback: 1.0 };
                window['webkit']['messageHandlers']['pacific'].postMessage(tokenRequest);
            }
            catch (error) {
                _this.handleError('tokenRequest');
                reject(error);
            }
        });
    };
    IOsBridge.prototype.handleError = function (error) {
        amplitude.track("Rewards IOSBridge Service Error", { error: error });
    };
    return IOsBridge;
}());
export { IOsBridge };
